import React from 'react'
import PropTypes, { shape } from 'prop-types'

const ProvenResultList = ({ list }) => (
  <ul
    className="relative flex w-fit flex-col self-center lg:w-full lg:flex-row lg:justify-between lg:pt-0 lg:text-black-base"
    id="proven-result-list"
  >
    {list.map((item) => (
      <li
        className="pt-8 after:absolute after:inset-x-0 after:mx-auto after:h-0.5 after:w-25 after:bg-blue-base-picton last:after:hidden lg:pt-0 lg:after:hidden"
        key={`${item.tagline}`}
      >
        <b className="block text-3.5xl font-normal leading-10 lg:text-2xl lg:font-medium">
          {item.quantity}
        </b>
        <span className="block pb-8 text-lg lg:pb-0">{item.tagline}</span>
      </li>
    ))}
  </ul>
)

ProvenResultList.propTypes = {
  list: PropTypes.arrayOf(
    shape({
      quantity: PropTypes.string.isRequired,
      tagline: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default ProvenResultList
