import React from 'react'
import PropTypes from 'prop-types'
import { renderable } from '../../../../common/propTypes'

const Content = ({ children, classNames }) => {
  return <div className={`brandPages w-content ${classNames}`}>{children}</div>
}

Content.defaultProps = {
  classNames: '',
}

Content.propTypes = {
  children: renderable.isRequired,
  classNames: PropTypes.string,
}

export default Content
