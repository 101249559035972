import React from 'react'
import PropTypes from 'prop-types'
import { renderable } from '../../../../common/propTypes'

const Section = ({ children, classNames }) => {
  return <section className={`py-12.2 ${classNames}`}>{children}</section>
}

Section.defaultProps = {
  classNames: '',
}

Section.propTypes = {
  children: renderable.isRequired,
  classNames: PropTypes.string,
}

export default Section
